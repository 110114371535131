<template>
  <div>
    <NavSection />
    <main>
      <slot></slot>
    </main>
    <FooterSection />
  </div>
</template>

<script>
import FooterSection from "@/components/FooterSection.vue";
import NavSection from "@/components/NavSection.vue";

export default {
  name: "DefaultLayout",
  components: {
    NavSection,
    FooterSection,
  },
};
</script>

<style scoped>
/* Add your styles here */
</style>
