<template>
  <DefaultLayout>
    <BannerSection
      overline="Fostering innovation, supporting young talents."
      title="Ekiti Young Innovators Programme 2024."
      callToAction="Apply Now"
    />
    <section class="brief_intro">
      <h1 class="brief_intro_h1">
        Welcome to the official call for applications for the Ekiti Young
        Innovators Programme 2024.
      </h1>
      <p>
        The aim of the Young Innovators Programme is to discover the next
        generation of innovators in Ekiti State—high-potential young individuals
        with exceptional business ideas. This programme seeks people with ideas
        that can be commercialized, address a problem and market need, offer
        significantly better solutions compared to existing ones, and are ready
        to be developed into new businesses. Additionally, the programme looks
        for individuals who can become relatable role models to inspire others.
      </p>
    </section>
    <!-- <section class="video_highlight">
      <video src="../../public/videos/01.mp4" playsinline="true" controls ></video>
    </section> -->

    <section class="about_intro">
      <h1 class="intro_h1">About the Young Innovators Programme</h1>
    </section>
    <section class="about_details">
      <p class="about_paragraph">
        The Young Innovators Programme is part of the
        <b>Place-Based Innovation (PBI)</b> intervention of
        <span class="tooltip">
          <b>Innovate UK Global Alliance Africa</b>
          <span class="tooltiptext"
            ><a href="https://iuk.ktn-uk.org/programme/africa/" target="_blank"
              >Visit website</a
            ></span
          >
        </span>
        in Ekiti State. This program aims to support young innovators with
        creative and groundbreaking ideas and solutions that address Ekiti
        State's priorities.
      </p>
      <p class="about_paragraph">
        In commemoration of <b>International Youth Day 2023</b>,
        <b>Innovate UK Global Alliance Africa</b>, in partnership with the
        <span class="tooltip">
          <b>Ministry of Innovation, Science, and Digital Economy</b>
          <span class="tooltiptext"
            ><a href="https://innovateekiti.com" target="_blank"
              >Visit website</a
            ></span
          >
        </span>
        celebrated young innovators in Ekiti State. This initiative reinforces
        the Governor's commitment to empowering youth through innovation and
        technology. From a pool of talented innovators and inventors, three
        exceptional young entrepreneurs were selected as finalists. These
        brilliant entrepreneurs have established their startups within Ekiti
        State, showcasing the region's potential for fostering groundbreaking
        ideas.
      </p>
      <p class="about_paragraph">
        Innovators in this programme must be willing to participate in all
        sessions, including training, mentoring, pitching, and other activities
        as defined by the
        <b>Ministry of Innovation</b>
        and <b>Innovate UK Global Alliance Africa</b>. The programme is
        sector-agnostic, allowing ideas from any sector, provided they address
        one of the urgent economic issues in the state.
        <br />
        The Governor remains steadfast in his support for young innovators and
        businesses in Ekiti State. The progress and success of these
        entrepreneurs highlight the region's vibrant potential and pave the way
        for a more prosperous future for all residents of Ekiti Kete.
      </p>
    </section>
    <section class="about_images">
      <div class="about_images_row">
        <div class="about_image_1">
          <img
            src="../../public/images/18.jpg"
            alt="about image"
            decoding="async"
            loading="lazy"
          />
        </div>
        <div class="about_image_2">
          <img
            src="../../public/images/17.jpg"
            alt="about image"
            decoding="async"
            loading="lazy"
          />
        </div>
        <div class="about_image_3">
          <img
            src="../../public/images/16.jpg"
            alt="about image"
            decoding="async"
            loading="lazy"
          />
        </div>
      </div>
      <div class="about_images_writeup">
        <h3>
          The Honourable Commissioner of Innovation, Science, & Digital Economy
          with the winners of the 2023 Young Innovators Award
        </h3>
      </div>
    </section>

    <section class="why_banner">
      <div class="why_writeup">
        <h2 class="why_h2 section_title_right">Who can apply</h2>
        <p class="section_paragraph_right">Young innovators who:</p>
        <div class="section_paragraph_right">
          <ul>
            <li>Are resident in Ekiti State.</li>
            <li>Have innovative idea that has been piloted or tested.</li>
            <li>
              Are willing to commit to the incubation and capacity building
              session.
            </li>
            <li>
              Are passionate about socio-economic development of Ekiti State
              through innovation.
            </li>
          </ul>
        </div>
        <a
          href="https://info.ktn-global.org/p/6VC3-EZL/young-innovators-programme"
          target="_blank"
        >
          <Button
            label="Apply Now"
            class="why_cta button section_button_right"
          />
        </a>
      </div>
      <div class="why_image section_image_left">
        <img
          src="../../public/images/02.jpg"
          alt="why image"
          decoding="async"
          loading="lazy"
        />
      </div>
    </section>

    <section class="features">
      <div class="features_image section_image_right">
        <img
          src="../../public/images/01.jpg"
          alt="features image"
          decoding="async"
          loading="lazy"
        />
      </div>
      <div class="features_writeup">
        <h2 class="features_h2 section_title_left">Why Should You Apply?</h2>
        <p class="section_paragraph_left">
          Through this program, you will gain access to
          <b>unparalleled mentorship</b>, <b>resources</b>, and
          <b>networking opportunities</b> to help you transform your business
          idea into a thriving enterprise.
        </p>
        <div class="section_paragraph_left">
          <ul>
            <li>Showcase your innovative idea.</li>
            <li>Get opportunity to pitch your idea.</li>
            <li>
              Attend business and innovation development incubation session.
            </li>
            <li>
              Get connected with mentors in UK, Kenya, South Africa and Nigeria.
            </li>
            <li>Receive fund award to support your idea.</li>
          </ul>
        </div>
        <a
          href="https://info.ktn-global.org/p/6VC3-EZL/young-innovators-programme"
          target="_blank"
        >
          <Button
            label="Apply Now"
            class="features_cta button section_button_left"
          />
        </a>
      </div>
    </section>

    <!-- <section class="join_yip">
      <div class="join_writeup">
        <h1 class="join_h1">Join the Ekiti Young Innovators Programme</h1>
        <p>
          Be part of an exciting journey to drive innovation and economic
          development in Ekiti State. Apply today and take the first step
          towards making a significant impact.
        </p>
        <p>
          For more information or inquiries, please contact us at
          <a
            class="contact"
            href="mailto:innovateekiti@gmail.com"
            rel="noreferrer"
            target="_blank"
          >
            innovateekiti@gmail.com </a
          >.
        </p>
      </div>
      <a
        href="https://info.ktn-global.org/p/6VC3-EZL/young-innovators-programme"
        target="_blank"
        class="join_cta_a"
      >
        <Button label="Apply Now" class="join_cta button" />
      </a>
    </section> -->
  </DefaultLayout>
</template>

<script>
import gsap from "gsap";
import DefaultLayout from "../layouts/DefaultLayout.vue";
import BannerSection from "@/components/BannerSection.vue";
import Button from "primevue/button";

export default {
  name: "HomePage",
  components: {
    DefaultLayout,
    BannerSection,
    Button,
  },
  mounted() {
    this.checkScreenSize();

    const sectionRightOverline = document.querySelectorAll(
      ".section_title_right, .section_paragraph_right, .section_button_right, .section_image_right"
    );
    this.animatedOverlineRightItems = Array.from(sectionRightOverline);
    gsap.set(this.animatedOverlineRightItems, { opacity: 0, x: -50 });
    const sectionLeftOverline = document.querySelectorAll(
      ".section_title_left, .section_paragraph_left, .section_button_left, .section_image_left"
    );
    this.animatedOverlineLeftItems = Array.from(sectionLeftOverline);
    gsap.set(this.animatedOverlineLeftItems, { opacity: 0, x: 50 });

    window.addEventListener("resize", this.checkScreenSize);
    window.addEventListener("scroll", this.handleSectionItemScroll);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.checkScreenSize);
    window.removeEventListener("scroll", this.handleSectionItemScroll);
  },
  methods: {
    checkScreenSize() {
      const rightElements = document.querySelectorAll(
        ".section_title_right, .section_paragraph_right, .section_button_right, .section_image_right"
      );
      const leftElements = document.querySelectorAll(
        ".section_title_left, .section_paragraph_left, .section_button_left, .section_image_left"
      );
      const classesToRemoveOnRight = [
        "section_title_right",
        "section_paragraph_right",
        "section_button_right",
        "section_image_right",
      ];
      const classesToRemoveOnLeft = [
        "section_title_left",
        "section_paragraph_left",
        "section_button_left",
        "section_image_left",
      ];
      rightElements.forEach((rightElement, index) => {
        if (index < 8) {
          if (window.innerWidth <= 480) {
            classesToRemoveOnRight.forEach((className) =>
              rightElement.classList.remove(className)
            );
          } else {
            classesToRemoveOnRight.forEach((className) =>
              rightElement.classList.add(className)
            );
          }
        }
      });
      leftElements.forEach((leftElement, index) => {
        if (index < 8) {
          if (window.innerWidth <= 480) {
            classesToRemoveOnLeft.forEach((className) =>
              leftElement.classList.remove(className)
            );
          } else {
            classesToRemoveOnLeft.forEach((className) =>
              leftElement.classList.add(className)
            );
          }
        }
      });
    },
    handleSectionItemScroll() {
      // eslint-disable-next-line no-unused-vars
      this.animatedOverlineRightItems.forEach((item, index) => {
        const rect = item.getBoundingClientRect();
        const isVisible = rect.top < window.innerHeight && rect.bottom >= 0;
        if (isVisible) {
          gsap.to(item, {
            opacity: 1,
            x: 0,
            duration: 0.6,
            ease: "power3.out",
            delay: 0.8,
          });
          this.animatedOverlineRightItems =
            this.animatedOverlineRightItems.filter((item) => item !== null);
        }
      });
      // eslint-disable-next-line no-unused-vars
      this.animatedOverlineLeftItems.forEach((item, index) => {
        const rect = item.getBoundingClientRect();
        const isVisible = rect.top < window.innerHeight && rect.bottom >= 0;
        if (isVisible) {
          gsap.to(item, {
            opacity: 1,
            x: 0,
            duration: 0.6,
            ease: "power3.out",
            delay: 0.8,
          });
          this.animatedOverlineLeftItems =
            this.animatedOverlineLeftItems.filter((item) => item !== null);
        }
      });
    },
  },
};
</script>

<style scoped>
.brief_intro,
.about_images,
.why_banner,
.features,
.join_yip {
  margin-bottom: 5rem;
}

/* Brief intro */
.brief_intro {
  width: 100%;
  height: auto;
  background-color: var(--black);
  padding: 1.5rem 20%;

  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.brief_intro_h1 {
  font-size: xx-large;
  font-family: "Poppins Bold";

  position: relative;

  padding-bottom: 0.5rem;
}

.brief_intro_h1::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 50%;
  border-bottom: 2px solid var(--vivid-purple);
  z-index: 1;
}

.brief_intro h1,
.brief_intro p {
  text-align: center;
  color: var(--white);
}

/* video */
.video_highlight {
  padding: 0 3% 5% 3%;

  display: flex;
  align-items: center;
  justify-content: center;
}
.video_highlight video {
  width: 80%;
  height: auto;
}

/* about */
.about_intro {
  padding: 0 3%;

  display: flex;
  align-items: center;
  justify-content: center;
}

.about_intro h1 {
  font-family: "Poppins Black";
  text-transform: uppercase;

  font-size: 2rem;
  text-align: center;

  position: relative;
}

.intro_h1::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 50%;
  border-bottom: 2px solid var(--primary-purple);
  z-index: 1;
}

.about_details {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* gap: 1rem; */

  padding: 1.5rem 3%;
}

.about_paragraph {
  width: 90%;
  text-align: justify;
  margin-bottom: 1rem !important;
}
/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: var(--primary-purple);
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  position: absolute;
  bottom: 100%; /* Position above the element */
  left: 50%; /* Center the tooltip horizontally */
  transform: translateX(-50%); /* Center alignment */
  z-index: 1;
}
.tooltiptext > a {
  color: var(--white) !important;
  font-weight: bold;
}
.tooltip .tooltiptext::after {
  content: " ";
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 50%; /* Center the arrow horizontally */
  transform: translateX(-50%); /* Center alignment */
  border-width: 5px;
  border-style: solid;
  border-color: var(--primary-purple) transparent transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

.about_images {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 1.5rem 3%;
}
.about_images_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

.about_image_1,
.about_image_2,
.about_image_3 {
  height: auto;
  width: 40%;

  padding: 0.3rem 0 0.3rem 0;

  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  background-color: var(--primary-purple);
}

.about_image_1 img,
.about_image_2 img,
.about_image_3 img {
  width: 98%;
  height: 98%;

  object-fit: contain;
  object-position: center;
}

.about_images_writeup h3 {
  font-family: "Poppins Black";
  text-align: center;
  font-size: 2rem;
}

/* why */
.why_banner {
  display: flex;
  align-items: center;
  gap: 2rem;

  padding: 1.5rem 3%;
}

.why_writeup {
  width: 50%;
  text-align: left;
}

.why_h2 {
  font-size: 2.5rem;
  font-family: "Poppins Bold";
  margin-bottom: 1.5rem !important;

  position: relative;
}

.why_h2::after {
  content: "";
  display: block;
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 50%;
  height: 2px;
  background-color: var(--primary-purple);
}

.why_writeup p {
  margin-bottom: 1rem !important;
}

.why_writeup p ul {
  margin-left: 2rem;
}

.why_cta,
.join_cta {
  margin-top: 1.5rem;
  padding: 0.5rem 1rem;
  border: none;
  background-color: var(--primary-purple);
  color: var(--white);
  font-size: large;
  font-family: "Poppins Bold";
  cursor: pointer;
}

.why_image {
  height: 25rem;
  width: 45%;

  position: relative;

  background-color: var(--primary-purple);
}

.why_image img {
  position: absolute;
  z-index: 1;
  bottom: -2%;
  left: -2%;

  width: 95%;
  height: 95%;

  object-fit: cover;
  object-position: center;
}

/* Features */
.features {
  display: flex;
  align-items: center;
  gap: 2rem;

  padding: 1.5rem 3%;
}

.features_writeup {
  width: 50%;
  text-align: right;
}

.features_h2 {
  font-size: 2.5rem;
  font-family: "Poppins Bold";
  margin-bottom: 1.5rem !important;

  position: relative;
}

.features_h2::after {
  content: "";
  display: block;
  position: absolute;
  bottom: -1px;
  right: 0;
  width: 50%;
  height: 2px;
  background-color: var(--primary-purple);
}
.section_paragraph_left {
  margin-bottom: 1rem !important;
}
.section_paragraph_left > ul {
  list-style: none;
  padding: 0;
}

.features_cta,
.join_cta {
  margin-top: 1.5rem;
  padding: 0.5rem 1rem;
  border: none;
  background-color: var(--primary-purple);
  color: var(--white);
  font-size: large;
  font-family: "Poppins Bold";
  cursor: pointer;
}

.features_image {
  height: 25rem;
  width: 45%;

  position: relative;

  background-color: var(--primary-purple);
}

.features_image img {
  position: absolute;
  z-index: 1;
  bottom: -2%;
  right: -2%;

  width: 95%;
  height: 95%;

  object-fit: cover;
  object-position: center;
}

/* Join YIP */
.join_yip {
  padding: 1.5rem 3%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.join_writeup {
  width: 80%;
  text-align: center;
}

.join_h1 {
  padding-bottom: 0.3rem;
  font-size: 2.5rem;
  font-family: "Poppins Bold";
  margin-bottom: 1rem !important;

  position: relative;
}

.join_h1::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 50%;
  border-bottom: 2px solid var(--primary-purple);
  z-index: -1;
}
.join_cta_a {
  width: 50%;
}
.join_cta {
  font-size: xx-large;
  width: 100%;
}

.contact {
  opacity: 0.7;
  font-style: italic;

  position: relative;
}

.contact::after {
  content: "";
  display: block;
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 0;
  height: 1px;
  background-color: var(--black);
  opacity: 0.7;
  transition: width 0.5s ease-in-out;
}

.contact:hover::after {
  width: 100%;
}

@media (min-width: 992px) and (max-width: 1199px) {
  /* Brief intro */
  .brief_intro {
    padding: 1.5rem 15%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* Brief intro */
  .brief_intro {
    padding: 1.5rem 10%;
  }

  /* about */
  .about_details {
    gap: 1rem;
  }

  .about_intro h1 {
    text-align: center;
    font-size: 1.7rem;
  }

  .about_paragraph {
    width: 95%;
  }

  .about_images_row {
    justify-content: center;
    flex-wrap: wrap;
  }

  .about_image_1,
  .about_image_2,
  .about_image_3 {
    width: 45%;
  }

  /* why */
  .why_h2 {
    font-size: 2.3rem;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  /* Brief intro */
  .brief_intro {
    padding: 1.5rem 8%;
  }

  /* about */
  .about_details {
    gap: 1rem;
  }

  .about_intro h1 {
    text-align: center;
    font-size: 1.7rem;
  }

  .about_paragraph {
    width: 95%;
  }

  .about_images_row {
    justify-content: center;
    flex-wrap: wrap;
    gap: 0.5rem;
  }

  .about_image_1,
  .about_image_2,
  .about_image_3 {
    width: 45%;

    padding: 0.2rem 0 0.2rem 0;
  }

  .about_images_writeup h3 {
    font-size: 1.7rem;
  }

  /* why */
  .why_banner {
    justify-content: space-between;
    gap: 0.5rem;
    padding: 1.5rem 3%;
  }

  .why_h2 {
    font-size: 1.7rem;
  }

  .why_writeup p,
  .why_writeup p ul {
    font-size: medium !important;
  }

  .why_image {
    height: 20rem;
    width: 45%;
  }

  /* features */
  .features {
    padding: 1.5rem 3%;
  }

  .features_h2 {
    font-size: 1.7rem;
  }

  .features_writeup p {
    font-size: medium !important;
  }

  .section_paragraph_left > ul {
    list-style: none;
    padding: 0;
  }

  .features_image {
    height: 20rem;
    width: 45%;
  }

  /* join yip */
  .join_writeup {
    width: 90%;
  }

  .join_h1 {
    font-size: 2.2rem;
  }
}

@media (max-width: 480px) {
  .brief_intro,
  .about_images,
  .why_banner,
  .features,
  .join_yip {
    margin-bottom: 3rem;
  }

  /* Brief intro */
  .brief_intro {
    padding: 1.5rem 6%;
  }

  /* about */
  .about_details {
    gap: 1rem;
  }

  .about_intro h1 {
    text-align: center;
    font-size: 1.7rem;
  }

  .about_paragraph {
    width: 95%;
  }

  .about_images_row {
    flex-direction: column;
    gap: 0.5rem;
  }

  .about_image_1,
  .about_image_2,
  .about_image_3 {
    width: 95%;
  }

  .about_images_writeup h3 {
    font-size: 1.7rem;
  }

  /* why */
  .why_banner {
    flex-direction: column;
    justify-content: space-between;
    gap: 2rem;
    padding: 1.5rem 6% !important;
  }

  .why_writeup {
    width: 100%;
  }

  .why_h2 {
    font-size: 1.7rem;
  }

  .why_writeup p,
  .why_writeup p ul {
    font-size: medium !important;
  }

  .why_image {
    display: none;
    height: 20rem;
    width: 90%;
  }

  /* features */
  .features {
    padding: 1.5rem 6% !important;
  }

  .features_writeup {
    width: 100%;
    text-align: left;
  }

  .features_h2 {
    font-size: 1.7rem;
  }

  .features_h2::after {
    left: 0;
  }

  .features_writeup p {
    font-size: medium !important;
  }

  .features_image {
    display: none;
    height: 20rem;
    width: 45%;
  }

  /* join yip */
  .join_writeup {
    width: 90%;
  }

  .join_h1 {
    font-size: 2rem;
  }
}
</style>
