<template>
  <main>
    <router-view />
  </main>
</template>

<script>
export default {
  name: "App",
  components: {},
};
</script>

<style>
:root {
  --vivid-purple: #9b51e0;
  --primary-purple: #5a0064;

  --color1: #31cf7e;
  --color2: #cfff35;
  --color3: #f55a47;
  --color4: #1a1a1a;
  --color5: #60a5fa;

  --white: #fff;
  --black: #000;
}
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0 !important;
}
a {
  text-decoration: none;
  color: var(--black);
}
.button {
  border-radius: 0;
}

@font-face {
  font-family: "Poppins";
  src: url("../public/fonts/poppins/Poppins-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Poppins Bold";
  src: url("../public/fonts/poppins/Poppins-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Poppins SemiBold";
  src: url("../public/fonts/poppins/Poppins-SemiBold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Poppins Black";
  src: url("../public/fonts/poppins/Poppins-Black.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
#app {
  font-family: "Poppins", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
