<template>
  <nav class="navbar">
    <div class="logos">
      <div class="logo">
        <a href="/">
          <img src="../assets/logo.png" alt="MISDE Logo" />
        </a>
      </div>
      <div class="logo">
        <a href="https://iuk.ktn-uk.org/" target="_blank">
          <img src="../assets/uk-ktn.png" alt="IUK Logo" />
        </a>
      </div>
    </div>

    <a
      href="https://info.ktn-global.org/p/6VC3-EZL/young-innovators-programme"
      target="_blank"
    >
      <Button label="Apply Now" class="navbar_cta button" />
    </a>
  </nav>
</template>

<script>
import Button from "primevue/button";
export default {
  name: "NavSection",
  components: {
    Button,
  },
};
</script>

<style scoped>
.navbar {
  width: 100%;
  padding: 0.5rem 3%;

  display: flex;
  align-items: center;
  justify-content: space-between;
}
.logos {
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 1rem;
}
.logo {
  width: 13rem;
  height: auto;
}
.logo img {
  width: 100%;
  height: 100%;
}

.navbar_cta {
  padding: 0.5rem 1rem;
  border: none;
  background-color: var(--primary-purple);
  color: var(--white);
  font-size: large;
  font-family: "Poppins Bold";
  cursor: pointer;
}

@media (min-width: 992px) and (max-width: 1199px) {
}
@media (min-width: 768px) and (max-width: 991px) {
}
@media (min-width: 481px) and (max-width: 767px) {
}
@media (max-width: 480px) {
  .navbar {
    padding: 0.7rem 5%;
    justify-content: center;
  }
  .logo {
    width: 12rem;
  }
  .navbar_cta {
    display: none;
  }
}
</style>
