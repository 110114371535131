<template>
  <section class="banner">
    <div class="banner_content">
      <div class="banner_writeup slide_left" v-if="overline || title">
        <p class="overline">{{ overline }}</p>
        <h1>{{ title }}</h1>
      </div>
      <a
        href="https://info.ktn-global.org/p/6VC3-EZL/young-innovators-programme"
        target="_blank"
      >
        <Button class="banner_cta slide_left button">{{ callToAction }}</Button>
      </a>
    </div>
    <div class="overlay"></div>
  </section>
</template>

<script>
import { gsap } from "gsap";
import Button from "primevue/button";
export default {
  name: "BannerSection",
  components: {
    Button,
  },
  props: {
    overline: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    callToAction: {
      type: String,
      default: "",
    },
  },
  async mounted() {
    this.animateBanner();
  },
  async beforeUnmount() {},
  methods: {
    animateBanner() {
      gsap.from(".slide_left", {
        duration: 0.5,
        x: -100,
        ease: "power2.out",
        opacity: 0,
        stagger: 0.9,
        delay: 0.7,
      });
    },
  },
};
</script>

<style scoped>
.banner {
  height: 80vh;

  background-image: url("/public/images/15.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  display: flex;
  align-items: center;

  position: relative;
  overflow: hidden;
}
.banner_content {
  padding: 0 3%;

  position: relative;
  z-index: 2;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;

  background-image: linear-gradient(to bottom, transparent, var(--black));
}
.banner_writeup {
  width: 90%;
}
.slide_left {
  opacity: 1;
}
.overline {
  color: var(--white);
  font-size: large;
  text-shadow: 1px 2px var(--black);
}
.banner_writeup h1 {
  font-family: "Poppins Bold";
  color: var(--white);
  font-size: 3.5rem;

  text-shadow: 2px 3px var(--black);
}

.banner_cta {
  padding: 0.5rem 1rem;
  border: none;
  background-color: var(--primary-purple);
  color: var(--white);
  font-size: large;
  font-family: "Poppins Bold";
  cursor: pointer;
}

@media (min-width: 992px) and (max-width: 1199px) {
}
@media (min-width: 768px) and (max-width: 991px) {
}
@media (min-width: 481px) and (max-width: 767px) {
  .banner_writeup {
    width: 100%;
  }
}
@media (max-width: 480px) {
  .banner {
    height: 80vh;
    background-position: center;
  }
  .banner_writeup {
    width: 100%;
  }
  .overline {
    font-size: large;
  }
  .banner_writeup h1 {
    font-size: 2.7rem;
  }
}
</style>
