// import AboutPage from '@/pages/AboutPage.vue';
import HomePage from '@/pages/HomePage.vue';
// import OpportunitiesPage from '@/pages/OpportunitiesPage.vue';
import PageNotFound from '@/pages/PageNotFound.vue';
import { createRouter, createWebHistory } from 'vue-router';


const routes = [
    {
        path: '/',
        name: 'Home',
        component: HomePage,
    },
    // {
    //     path: '/about',
    //     name: 'About',
    //     component: AboutPage,
    // },
    // {
    //     path: '/opportunities',
    //     name: 'Opportunities',
    //     component: OpportunitiesPage,
    // },
    {
        path: '/:catchAll(.*)*',
        name: "PageNotFound",
        component: PageNotFound,
    },
    // Add more routes here
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;