<template>
  <footer>
    <section class="partners">
      <div class="partner_writeup">
        <h1>The Ekiti Young Innovators Programme</h1>
        <p>
          Join us in our mission to empower the brightest minds and boldest
          ideas in Ekiti State. Together, we're shaping the future of
          innovation, science, and digital economy.
        </p>
      </div>
      <div class="partner_images">
        <a href="/">
          <img
            src="../assets/logo.png"
            alt="MISDE Logo"
            decoding="async"
            loading="lazy"
          />
        </a>
        <a href="https://iuk.ktn-uk.org/" target="_blank">
          <img
            src="../assets/uk-ktn.png"
            alt="IUK Logo"
            decoding="async"
            loading="lazy"
          />
        </a>
      </div>
    </section>
    <section class="contact_social">
      <div class="contact_info">
        <h2>For more information or inquiries, please contact us at:</h2>
        <a href="https://wa.me/+2347047000309" target="_blank"
          >+2347047000309</a
        >
        <a
          href="mailto:innovateekiti@gmail.com"
          rel="noreferrer"
          target="_blank"
        >
          <i>innovateekiti@gmail.com</i></a
        >.
      </div>
      <div class="socials_navigation">
        <div class="socials">
          <p>
            Follow us on social media for the latest updates and opportunities!
          </p>
          <div class="icons">
            <a
              class="social_icon"
              href="https://x.com/MISDEEkiti"
              target="_blank"
            >
              <img
                src="../assets/icons/x.svg"
                decoding="async"
                loading="lazy"
              />
            </a>
            <a
              class="social_icon"
              href="https://www.instagram.com/misdeekiti/"
              target="_blank"
            >
              <img
                src="../assets/icons/instagram.svg"
                decoding="async"
                loading="lazy"
              />
            </a>
            <a
              class="social_icon"
              href="https://www.facebook.com/MISDEEkiti?mibextid=LQQJ4d"
              target="_blank"
            >
              <img
                src="../assets/icons/facebook.svg"
                decoding="async"
                loading="lazy"
              />
            </a>
            <!-- <a
                class="social_icon"
                href="https://www.facebook.com/share/3zLLa6sNZZp83eZX/?mibextid=LQQJ4d"
                target="_blank"
              >
                <img src="../assets/icons/linkedin.svg" decoding="async" />
              </a> -->
            <!-- <a
                class="social_icon"
                href=""
                target="_blank"
              >
                <img src="../assets/icons/youtube.svg" alt="youtube icon" />
              </a> -->
          </div>
          <div class="copyright">
            <p>
              Copyright © 2024. <br />
              All rights reserved.
            </p>
          </div>
        </div>
      </div>
    </section>
  </footer>
</template>

<script>
export default {
  name: "FooterSection",
  components: {},
};
</script>

<style scoped>
footer {
  background-color: var(--black);
  padding: 0 0 10rem 0;

  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.partners {
  padding: 2% 3% 2% 3%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;

  background-color: var(--white);

  position: relative;
  overflow: hidden;
}
.partner_writeup {
  width: 50%;
}
.partner_writeup h1 {
  font-size: 1.5rem;
  font-family: "Poppins Bold";
  color: var(--black);
}
.partner_writeup p {
  color: var(--black);
}
.partner_images {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.partners a {
  width: fit-content;
  height: fit-content;
}
.partners a img {
  width: 15rem;
}

.contact_social {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  padding: 0 3% 0 3%;
}
.contact_info {
  display: flex;
  flex-direction: column;
}
.contact_info h2 {
  font-size: 1rem;
  font-family: "Poppins Bold";
  color: var(--white);

  margin-bottom: 1rem !important;
}
.contact_info a {
  color: var(--white);
}
.socials_navigation {
  width: 50%;
}
.footer_menu {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 0.5rem;

  margin-bottom: 2rem;
}
.footer_menu li {
  text-decoration: none;
}
.footer_menu li a {
  text-decoration: underline;
  font-weight: bold;
  color: var(--white);
}
.socials {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  gap: 0.5rem;
}
.socials > p {
  color: var(--white);
  text-align: right;
}
.icons {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.social_icon {
  height: 2rem;
  width: 2rem;
  padding: 3%;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: var(--white);

  transition: opacity 0.2s ease-in-out;
}
.social_icon:hover {
  opacity: 0.8;
}
.social_icon img {
  width: 80%;
}
.copyright {
  margin-top: 3rem;
  text-align: right;
}
.copyright p {
  font-size: 14px;
  color: var(--white);
}

@media (min-width: 992px) and (max-width: 1199px) {
}
@media (min-width: 768px) and (max-width: 991px) {
  .partner_writeup {
    width: fit-content;
  }
  .partner_writeup h1 {
    font-size: 1.2rem;
  }
  .partners a img {
    width: 12rem;
  }
}
@media (min-width: 481px) and (max-width: 767px) {
  .partners {
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
  }
  .partner_writeup {
    width: fit-content;
  }
  .partner_images {
    gap: 2rem;
  }
}
@media (max-width: 480px) {
  .partners {
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
  }
  .partner_writeup {
    width: fit-content;
  }
  .partner_writeup h1 {
    font-size: 1.2rem;
  }
  .partner_images {
    gap: 1rem;
  }
  .partners a img {
    width: 12rem;
  }
  .contact_info {
    height: fit-content;
    justify-content: center;
  }
  .contact_info a {
    font-size: small;
  }
  .contact_social {
    flex-direction: column;
    gap: 0.5rem;
  }
  .contact_info h2 {
    font-size: small;
    margin-bottom: 0.7rem !important;
  }
  .socials_navigation {
    width: fit-content;
  }
  .socials {
    align-items: flex-start !important;
  }
  .socials > p {
    text-align: left;
    font-size: small;
  }
  .copyright {
    text-align: left;
    margin-top: 0.5rem;
  }
}
</style>
