/* eslint-disable vue/multi-word-component-names */
import { createApp } from 'vue';
import router from './router';
import PrimeVue from 'primevue/config';
import App from './App.vue';
import Button from "primevue/button";
import InputText from 'primevue/inputtext';


import 'primevue/resources/themes/lara-light-purple/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import BannerSection from './components/BannerSection.vue';

const app = createApp(App);
app.use(router);
app.use(PrimeVue);
app.component('BannerSection', BannerSection);
app.component('Button', Button);
app.component('InputText', InputText);
app.mount('#app');
